@import "../parts/variables-main";
@import "../parts/custom";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "../parts/mixins";

::selection{ background-color: $main_color; color: white; }
::moz-selection{ background-color: $main_color; color: white; }
::webkit-selection{ background-color: $main_color; color: white; }
body{
  background-color: $main_bg;
  font-family: $main_font_family;
  color: $main_text_color;
  font-size: 43px;
  font-weight: 700;
  line-height: 53px;
  margin: 0;
}
h1{
  color:  $main_text_color;
  font-size: 76px;
  font-weight: 700;
  line-height: 80px;
  margin: 0 0 3px 0;
}
#logo{
  position: absolute;
  top: 10%;
  left: 0;
  transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  margin: 0 135px;

  img{
    height: 50px;
    width: auto;
    max-width: none;
    margin: 0;
  }
}
#container{
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  margin: 0 135px;
}
p{
  margin: 0;
}
img{
  margin: 30px 0 0 0;
  max-width: 100%;
}
.button{
  margin: 75px 0 0 0;
  display: inline-block;
  background-color: $main_color;
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  text-shadow: 1px 2px 0px rgba(0, 0, 0, 0.13);
  text-decoration: none;
  padding: 13px 30px;
  outline: none;
  border: none;
  transition: All 0.5s ease;
  -webkit-transition: All 0.5s ease;
  -moz-transition: All 0.5s ease;
  -o-transition: All 0.5s ease;
  position: relative;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  @media screen and (prefers-reduced-motion) {
    transition: none;
  }

  > *{
    position: relative;
    z-index: 1;
  }

  &.button--rounded {
    border-radius: 26px;
  }

  &:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#000, 0.15);
    z-index: 0;
    transition: All 0.5s ease;
    -webkit-transition: All 0.5s ease;
    -moz-transition: All 0.5s ease;
    -o-transition: All 0.5s ease;
    opacity: 0;

    @media screen and (prefers-reduced-motion) {
      transition: none;
    }
  }
  &:hover, &:focus, &:active {
    color: #ffffff;

    &:before{
      opacity: 1;
    }
  }
}


/* MD Medium devices (desktops, 992px and up) */
@include media-breakpoint-down(sm){
  body{
    font-size: 24px;
    line-height: 30px;
  }
  h1{
    font-size: 42px;
    line-height: 48px;
  }
  //#logo{
  //  margin: 0 30px;
  //
  //  img{
  //    height: 40px;
  //  }
  //}
  //#container{
  //  margin: 0 30px;
  //}
  .button{
    font-size: 20px;
    line-height: 26px;
  }
}
@include media-breakpoint-down(xs){
  body{
    font-size: 20px;
    line-height: 26px;
  }
  h1{
    font-size: 36px;
    line-height: 42px;
  }
  #logo{
    margin: 0 30px;

    img{
      height: 40px;
    }
  }
  #container{
    margin: 0 30px;
  }
  .button{
    font-size: 18px;
    line-height: 24px;
  }
}