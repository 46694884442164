// -- main variables
$main_font_family: var(--font_main_family);
$main_font_size: 16px;
$main_font_weight: 400;
$main_line_height: 1.6;

// @todo - pozbyć się zmiennej $colors na rzecz zmiennych css lub bootstrap
$colors: (
        "white": #ffffff,
        "red": #F61237,
        "grey": #888888,
        "light-grey": #E5E2DE,
        //"dark-grey": #231f20,
        "black": #000000,
);

$main_text_color: var(--color_font_page);
$main_bg: var(--color_bg_page);
$main_color: var(--color_bg_button);
$main_border_color: $main_text_color;
$main_border: 1px solid $main_border_color;
$main_border_radius: 0px;

$header_font_family: var(--font_header_family);
$headline_bg: var(--color_bg_title);
$subheader_font_family: var(--font_subheader_family);

$menu_font_family: var(--font_menu_family);
$menu_font_weight: var(--menu_font_weight);
$menu_font_transform: var(--menu_font_transform);
$menu_font_style: var(--menu_font_style);
$menu_button_font_transform: var(--menu_button_font_transform);

$link_color: $main_color;
$link_text_decoration: none;
$link_hover_color: $main_color;
$link_hover_text_decoration: underline;
$error_color: map-get($colors,"red");

$main_font_size_map: (
  min: 14px,
  max: 16px
);

$inverse_bg: #000000;
$inverse_color: #ffff00;
$inverse_text_color: #FFFFFF;
$inverse_border_color: #aaa;